/* JQUERY UI TABS */
.ui-widget.ui-widget-content.ui-tabs {
    border: none;
}

.ui-tabs {
    position: relative; /* position: relative prevents IE scroll bug (element with position: relative inside container with overflow: auto appear as "fixed") */
    padding: .2em 0;
}

.ui-tabs .ui-tabs-nav {
    margin: -4px;
    padding: .2em 5px 0;
    box-shadow: none;
    background: #fafafa;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
    color: #888;
    outline: none;
}

.ui-state-active a, .ui-state-active a:link {
    color: #333;
}

.ui-tabs .ui-tabs-nav li {
    list-style: none;
    float: left;
    position: relative;
    top: 0;
    margin: 1px .2em 0 0;
    border-bottom-width: 0;
    padding: 0 0 1px;
    white-space: nowrap;
}

.ui-tabs .ui-tabs-nav .ui-tabs-anchor {
    float: left;
    padding: .5em 1em;
    text-decoration: none;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active {
    margin-bottom: -1px;
    padding-bottom: 1px;
    background: #fff;
    border: 1px solid #c5c5c5;
    color: #333;
    border-bottom-color: #fff;
}

.ui-tabs .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-state-disabled .ui-tabs-anchor,
.ui-tabs .ui-tabs-nav li.ui-tabs-loading .ui-tabs-anchor {
    cursor: text;
}

.ui-tabs-collapsible .ui-tabs-nav li.ui-tabs-active .ui-tabs-anchor {
    cursor: pointer;
}

.ui-tabs .ui-tabs-panel {
    display: block;
    border-width: 0;
    padding: 1em 0;
    background: none;
}
