html, body {
    padding: 0;
    margin: 0;
    font-family: Helvetica, Arial;
    color: #3A4244;
}

.clearfix:before, .clearfix:after {
    content: "";
    display: table
}

.clearfix:after {
    clear: both
}

.clearfix {
    *zoom: 1
}

.pointer {
    cursor: pointer;
}

section {
    padding: 0 30px;
}

/*select2 z-index fix*/
.select2-container {
    z-index: 100;
}

div.popup .select2-container, .select2-container--open {
    z-index: 10000;
}

p.error {
    font-weight: bold;
    color: red;
}

p.success {
    font-weight: bold;
    color: #008004;
}

dt, dd, dl {
    display: inline-block;
    margin: 0;
}

h3 {
    font-size: 1em;
    margin: 0.5em 0;
}

.pseudo {
    background: white;
    color: black;
    border: 0;
}

.inline-label {
    font-size: 0.6em;
    color: #ffffff;
    background: #858585;
    border-color: #444444;
    border-radius: 4px;
    border: solid 1px;
    padding: 2px;
    margin-left: 5px;
    -moz-border-radius: 4px;
}
