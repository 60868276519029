.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}

.action-icon {
    color: #999;
    text-decoration: none;
    font-size: 25px;
    margin: 13px 2px;
}

.action-icon-red {
    color: #990009;
    text-decoration: none;
    font-size: 25px;
    margin: 13px 2px;
}

.warning, .updated {
    text-decoration: none;
    font-size: 20px;
    margin: 0 5px 0 0;
    line-height: 22px;
    transition: color .3s ease-out;
}

.warning {
    color: rgba(88, 0, 255, 0.41);
}

.updated {
    color: rgba(255, 0, 0, 0.88);
}