div.popup {
    box-shadow: 0 14px 45px rgba(0, 0, 0, 0.25), 0 10px 18px rgba(0, 0, 0, 0.22);
    border-radius: 2px;
    font-family: 'Open Sans', sans-serif;
    padding: 24px;
    font-size: 15px;
    line-height: 24px;

    h1 {
        font-size: 24px;
        line-height: 32px;

        &:first-child {
            margin-top: 0;
        }
    }
}