
.mCSB_scrollTools{
    .mCSB_draggerContainer {
        padding: 5px 0;
    }
    .mCSB_draggerRail {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.2);
        filter: "alpha(opacity=20)";
        -ms-filter: "alpha(opacity=20)";
        width: 7px;
    }
    .mCSB_dragger_bar {
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.3);
        filter: "alpha(opacity=3)";
        -ms-filter: "alpha(opacity=3)";
        width: 7px;
        margin: 5px auto 0;
    }
}

.mCSB_inside > .mCSB_container {
    margin-right: 12px;
}