.imasseges {
    position: fixed;
    top: 55px;
    right: 20px;
}

.imassege {
    padding: 10px 20px 10px 13px;
    width: 220px;
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    border-radius: 3px;
    margin: 0 0 10px;
    font-family: 'Open Sans', sans-serif;
    position: relative;
    word-wrap: break-word;

    &.success {
        background-color: rgba(126, 218, 139, 0.8);
    }
    &.info {
        background-color: rgba(151, 190, 255, 0.80);
    }
    &.error {
        background-color: rgb(243, 77, 14);
    }
}

.imassege-close {
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    cursor: pointer;
    font-size: 14px;
}