.frame {
    background-color: #ffffff;
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    margin-bottom: 20px 0;
}

.frame_header {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: rgb(232, 232, 232);
    height: 56px;
    width: 100%;
    padding: 0px 24px;
    line-height: 56px;
    font-size: 20px;
    position: relative;
    color: rgba(0, 0, 0, .40);
}

.frame_body {
    position: relative;
    padding: 15px 24px;
}

.material-style {
    font-family: 'Open Sans', sans-serif;
}

.material-style .content {
    margin: 0 -30px;
    background: #f5f5f5;
    box-shadow: 0 4px 4px -4px #aaa inset, 0 -4px 4px -4px #aaa inset;
    padding: 20px 30px;
}

.material-style input[type="text"],
.material-style input[type="password"],
.material-style textarea {
    border: none;
    border-bottom: solid 1px #e0e0e0;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 0;
    background: transparent;
    outline: none;
    transition: all .2s ease-out;
    width: 100%;
    display: block;
    box-shadow: none;
    border-radius: 0;

    &:disabled{
        opacity: 1;
    }
    &:focus{
        border-bottom-color: #00BCD4;
        box-shadow: 0 -1px 0 #00BCD4 inset;
    }
}

.material-style textarea {
    padding-bottom: 8px;
    padding-right: 25px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    min-height: 40px;
    height: 27px;
    resize: vertical;
}

.material-style label {
    color: rgba(0, 0, 0, 0.4);
    display: block;
    font-size: 13px;
    line-height: 15px;
    margin-bottom: -5px;

    strong {
        color: rgba(0, 0, 0, 0.45);
    }
    em {
        font-size: 10px;
        color: #0077d4;
        vertical-align: sub;
        position: absolute;
        right: 2px;
        bottom: 0;
    }
    small {
        display: inline-block;
        vertical-align: super;
        margin: 0 0 0 5px;
        opacity: .5;
    }
}

.material-style fieldset {
    border: none;
    padding: 0;
    margin: 15px 0;
    position: relative;
    overflow: hidden;
}

.material-style legend {
    color: rgba(0, 0, 0, 0.67);
    display: block;
    margin: 15px 0;
    font-size: 18px;
    line-height: 24px;
    display: block;
}




.material-style input[type="checkbox"] {
    display: none;

    & + label {
        cursor: pointer;
        position: relative;
        padding-left: 25px;
        font-size: 16px;
        line-height: normal;
        margin: 0;
        height: auto;
        overflow: visible;

        &:before {
            content: '';
            width: 16px;
            height: 16px;
            background: transparent;
            border: 2px solid #666;
            border-radius: 2px;
            display: block;
            position: absolute;
            top: 1px;
            left: 0;
            transition: all .2s ease-out;
        }
        &:hover:before {
            background: rgba(0, 188, 212, .7);
            border-color: rgb(0, 188, 212);
        }
    }
    &:checked + label{
        &:before {
            background: rgba(0, 188, 212, 1);
            border-color: rgb(0, 188, 212);
        }
        &:after {
            content: '';
            width: 6px;
            height: 10px;
            background: transparent;
            border: 2px solid #fff;
            border-style: none solid solid none;
            display: block;
            position: absolute;
            top: 3px;
            left: 6px;
            transition: all .2s ease-out;
            -webkit-transform: rotate(42deg);
            -moz-transform: rotate(42deg);
            -o-transform: rotate(42deg);
            -ms-transform: rotate(42deg);
            transform: rotate(42deg);

        }
    }
}