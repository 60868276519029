
.loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(255, 255, 255, .6);
}

.loader img {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
}